import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import {
  avatarFallback,
  avatarImage,
  avatarRoot,
  avatarRootSize,
  AvatarSize,
} from './avatar.css.ts';

export interface AvatarProps extends ComponentPropsWithoutRef<'span'> {
  name: string;
  imageSrc?: string | null;
  size?: AvatarSize;
}

export const Avatar: FC<AvatarProps> = ({
  className,
  size = 'small',
  name,
  imageSrc,
}) => (
  <AvatarPrimitive.Root
    className={clsx(avatarRoot, avatarRootSize[size], className)}
  >
    <AvatarPrimitive.Image
      className={avatarImage}
      src={imageSrc ?? undefined}
      alt={'Avatar'}
    />
    <AvatarPrimitive.Fallback
      delayMs={imageSrc ? 600 : 0}
      className={avatarFallback}
    >
      {name[0].toUpperCase()}
    </AvatarPrimitive.Fallback>
  </AvatarPrimitive.Root>
);
