import { Avatar, AvatarProps } from '@synoptic/ui-kit/avatar/avatar.js';
import { getUserS3Url } from './get-user-s3-url';
import { avatarSizesPx } from '@synoptic/ui-kit/avatar/avatar.css.js';

export type S3AvatarProps = {
  s3Key?: string;
  userId: string;
} & Omit<AvatarProps, 'imageSrc'>;

export const S3Avatar: React.FC<S3AvatarProps> = ({
  s3Key,
  userId,
  size = 'small',
  ...avatarProps
}) => {
  const url = getUserS3Url(userId, s3Key, {
    w: avatarSizesPx[size],
    h: avatarSizesPx[size],
  });

  return <Avatar imageSrc={url?.toString()} size={size} {...avatarProps} />;
};
