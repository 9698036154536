import { MEDIA_SERVICE_DOMAIN_NAME } from '../../env';

export type MediaOpts = {
  w?: number;
  h?: number;
  /**
   * resizing type
   * @link https://docs.imgproxy.net/usage/processing#resizing-type
   * @default 'fit'
   */
  rs?: 'fill' | 'fit';
};

const getDpr = () =>
  typeof document === 'undefined' ? 1 : Math.ceil(window.devicePixelRatio);

const getResizedPath = (
  path: string,
  { w, h, rs = 'fit' }: { w: number; h: number; rs?: MediaOpts['rs'] },
): string =>
  `_/rs:${rs}:${Math.round(w)}:${Math.round(h)}/dpr:${getDpr()}/plain/${path}@jpg`;

export const getS3Url = (path?: string, { w, h, rs }: MediaOpts = {}) =>
  path
    ? new URL(
        `https://${MEDIA_SERVICE_DOMAIN_NAME}/${w && h ? getResizedPath(path, { w, h, rs }) : path}`,
      )
    : path;
